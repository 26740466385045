import { FormErrorMessage } from '@components/forms/FormErrorMessage';
import { Button, Card, Container, TextField, Typography } from '@mui/material';
import { useAuthContext } from 'pages/Login/useAuthContext';
import { Controller, useForm } from 'react-hook-form';
import hippoSvg from 'assets/hippo.svg';

type LoginFormValues = {
  username: string;
  password: string;
};

type LoginPageProps = {
  error?: Error | null;
};

export const LoginPage = (props: LoginPageProps) => {
  const { error: apiError } = props;

  const { loginUser } = useAuthContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = handleSubmit((data) => {
    return loginUser(data.username, data.password);
  });

  return (
    <div
      style={{
        width: '100%',
        minHeight: '100%',
        backgroundColor: '#8B0000',
      }}
    >
      <Container
        fixed
        sx={{
          paddingTop: '40px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Card sx={{ width: 400, padding: '40px', borderRadius: '12px' }}>
          <div style={{ textAlign: 'center', position: 'relative' }}>
            <img src={hippoSvg} alt="" style={{ width: 250 }} />
          </div>

          <div style={{ textAlign: 'center' }}>
            <div>
              <h1 style={styles.header}>Intus</h1>
              <h1 style={{ ...styles.header, ...styles.careText }}>Care</h1>
            </div>

            <div>
              <h2
                style={{
                  ...styles.header,
                  fontSize: 35,
                  fontWeight: 'bold',
                  marginBottom: 12,
                  color: '#404040',
                }}
              >
                Admin Portal
              </h2>
            </div>

            <form onSubmit={onSubmit}>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
              >
                {apiError != null && (
                  <div>
                    <Typography color="error">{apiError.message}</Typography>
                  </div>
                )}

                <div>
                  <Controller
                    control={control}
                    name="username"
                    rules={{ required: 'Email is required' }}
                    render={({ field }) => (
                      <TextField
                        label="Email"
                        variant="outlined"
                        sx={{
                          width: 250,
                        }}
                        {...field}
                      />
                    )}
                  />

                  <FormErrorMessage error={errors.username} />
                </div>

                <div>
                  <Controller
                    control={control}
                    name="password"
                    rules={{ required: 'Password is required' }}
                    render={({ field }) => (
                      <TextField
                        label="Password"
                        variant="outlined"
                        type="password"
                        sx={{
                          width: 250,
                        }}
                        {...field}
                      />
                    )}
                  />

                  <FormErrorMessage error={errors.password} />
                </div>

                <div>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      width: 250,
                    }}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Card>
      </Container>
    </div>
  );
};

const styles = {
  header: {
    fontFamily: 'Inter',
    display: 'inline-block',
    margin: 0,
    fontSize: '49px',
    fontWeight: 400,
  },
  careText: {
    fontWeight: 600,
    letterSpacing: 'calc(-0.3px)',
    color: '#4d7ef8',
  },
  adminPortalText: {
    fontSize: 23,
  },
} satisfies InlineCss;
