import { get } from '@api/api';

export type GetOrganizationsApiReturn = {
  id: number;
  name: string;
  active: boolean;
  flags: string[];
  ehr: string;
  subscriptions: string[];
  careAdminContactNumber: string | null;
  settings: {
    type: string;
    value: any;
  }[];
};

export async function getOrganizations() {
  return get<GetOrganizationsApiReturn[]>('/organizations');
}
