import React from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { getFeatureFlagsAndEnablers } from '@api/featureFlags/getFeatureFlagsAndEnablers';

import {
  Button,
  Checkbox,
  TextField,
  FormControlLabel,
  Box,
  Autocomplete,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import ReusableDialog from '@components/Dialog';
import {
  ICreateOrganizationPayload,
  createOrganization,
} from '@api/organizations/createOrganization';
import {
  IUpdateOrganizationsPayload,
  updateOrganization,
} from '@api/organizations/updateOrganization';
import { useLazyQuery, useQuery } from '@api/useQuery';
import { FormErrorMessage } from '@components/forms/FormErrorMessage';
import { GetOrganizationsApiReturn } from '@api/organizations/getOrganizations';
import { orderBy } from 'lodash';

type CreateEditOrganizationModalProps = {
  open: boolean;
  onClose: () => void;
  editOrg: GetOrganizationsApiReturn | null; // Update the type here
  fetchOrganizationList: () => void;
};

type OrganizationFormValues = Omit<GetOrganizationsApiReturn, 'id'>;

export const CreateEditOrganizationModal: React.FC<
  CreateEditOrganizationModalProps
> = ({ open, onClose, editOrg, fetchOrganizationList }) => {
  // Dropdown options
  let { data: allFlags = [] } = useQuery(() => getFeatureFlagsAndEnablers());
  const subscriptions = ['ACUITY', 'COHORTS', 'DASHBOARD'];
  const ehrList = ['COGNIFY', 'RTZ', 'TRUCHART'];

  allFlags = orderBy(allFlags, (f) => f.description?.toLowerCase());

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<OrganizationFormValues>({
    defaultValues: {
      name: '',
      careAdminContactNumber: '',
      ehr: '',
      active: true,
      subscriptions: [],
      flags: [],
      ...editOrg,
      settings:
        editOrg?.settings.map((s) => {
          return {
            ...s,
            value: JSON.stringify(s.value),
          };
        }) ?? [],
    },
    mode: 'onChange',
  });

  const { runQuery: runCreateOrgQuery } = useLazyQuery(
    (newOrg: ICreateOrganizationPayload) => {
      return createOrganization({ ...newOrg });
    },
    {
      onSuccess: () => {
        fetchOrganizationList();
        onClose();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { runQuery: runEditOrganizationQuery } = useLazyQuery(
    (editedOrgToSave: IUpdateOrganizationsPayload) => {
      return updateOrganization(editedOrgToSave.id, editedOrgToSave);
    },
    {
      onSuccess: () => {
        fetchOrganizationList();
        onClose();
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );

  const onSubmit = handleSubmit((data) => {
    if (editOrg) {
      runEditOrganizationQuery({
        ...data,
        id: editOrg.id!,
      });
    } else {
      runCreateOrgQuery(data);
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'settings',
  });

  return (
    <ReusableDialog
      title={editOrg ? 'Edit Organization' : 'Create Organization'}
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <Box component="form" onSubmit={onSubmit}>
        <div style={styles.textFieldRow}>
          <div style={{ flex: '1 1 100%', ...styles.textFieldContainer }}>
            <TextField
              fullWidth
              label="Organization Name"
              variant="outlined"
              {...register('name', { required: 'Name is required' })}
            />
            <FormErrorMessage error={errors.name} />
          </div>
          <TextField
            label="Contact Number"
            variant="outlined"
            type="tel"
            style={{
              width: '400px',
            }}
            {...register('careAdminContactNumber')}
          />
          <Controller
            name="ehr"
            control={control}
            rules={{ required: 'EHR is required' }}
            render={({ field }) => (
              <FormControl style={{ width: '350px' }}>
                <InputLabel id="ehr-label">EHR</InputLabel>
                <Select {...field} labelId="ehr-label" label="EHR">
                  {ehrList.map((ehr) => (
                    <MenuItem key={ehr} value={ehr}>
                      {ehr}
                    </MenuItem>
                  ))}
                </Select>
                <FormErrorMessage error={errors.ehr} />
              </FormControl>
            )}
          />

          <div>
            <Controller
              name="active"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Active"
                />
              )}
            />
          </div>
        </div>

        <div style={styles.textFieldRow}>
          <Controller
            name="subscriptions"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <Autocomplete
                multiple
                options={subscriptions}
                value={field.value}
                isOptionEqualToValue={(option, value) => {
                  return option === value;
                }}
                onChange={(_e, newValue) => field.onChange(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Subscriptions"
                  />
                )}
                style={{ flex: 1 }}
              />
            )}
          />
        </div>

        <div style={styles.textFieldRow}>
          <Controller
            name="flags"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <Autocomplete
                multiple
                options={allFlags?.map((f) => f.id) ?? []}
                value={orderBy(field.value, (flag) =>
                  allFlags
                    ?.find((f) => f.id === flag)
                    ?.description?.toLowerCase()
                )}
                isOptionEqualToValue={(option, value) => {
                  return option === value;
                }}
                getOptionLabel={(option) =>
                  allFlags?.find((f) => f.id === option)?.description ?? ''
                }
                onChange={(_e, newValue) => field.onChange(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Feature Flags"
                  />
                )}
                style={{ flex: 1 }}
              />
            )}
          />
        </div>

        <p>Org Settings</p>
        <div style={styles.orgSettingTextFieldRow}>
          {fields.map((field, index) => (
            <div key={field.id} style={styles.orgSettingInnerTextFieldRow}>
              <div style={{ flex: '1 1 350px' }}>
                <TextField
                  fullWidth
                  label="Type"
                  variant="outlined"
                  {...register(`settings.${index}.type`, {
                    required: 'Type is required.',
                  })}
                />
                <FormErrorMessage error={errors.settings?.[index]?.type} />
              </div>

              <div style={{ flex: '1 1 100%' }}>
                <TextField
                  fullWidth
                  label="Value"
                  variant="outlined"
                  {...register(`settings.${index}.value`, {
                    required: 'Value is required.',
                    validate: (value) => {
                      if (!value) {
                        return 'Value is required.';
                      }
                      try {
                        JSON.parse(value as any);
                      } catch (e) {
                        return 'Value must be a valid JSON.';
                      }
                      return true;
                    },
                  })}
                />
                <FormErrorMessage
                  error={errors.settings?.[index]?.value as any}
                />
              </div>

              <IconButton
                style={{ flex: '0 0 auto' }}
                aria-label="delete"
                onClick={() => remove(index)}
              >
                <ClearIcon aria-label="delete" />
              </IconButton>
            </div>
          ))}
          <Button
            variant="outlined"
            onClick={() => append({ type: '', value: '' })}
            style={{ width: 'fit-content' }}
          >
            Add Org Settings
          </Button>
        </div>

        <div style={{ marginTop: 16 }}>
          <Button variant="contained" type="submit" style={{ width: '100%' }}>
            Submit
          </Button>
        </div>
      </Box>
    </ReusableDialog>
  );
};

const styles = {
  textFieldRow: {
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: 'center',
    gap: 10,
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  orgSettingTextFieldRow: {
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    gap: 10,
    borderRadius: 5,
  },
  orgSettingInnerTextFieldRow: {
    display: 'flex',
    padding: 5,
    gap: 10,
    width: '100%',
  },
} satisfies InlineCss;
