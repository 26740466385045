import { post } from '@api/api';

export type ICreateOrganizationPayload = {
  name: string;
  active: boolean;
  flags: string[];
};

export function createOrganization(toCreate: ICreateOrganizationPayload) {
  return post('/organization', toCreate);
}
